<template>
  <div class="BatchProjectApplicationLibranyTenantForm-wrapper">
    <form-panel
      :submitUrl="submitUrl"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block title="批量设置项目授权应用">
          <el-form-item label="所属公司" :rules="[{ required: true, message: '请选择公司', trigger: 'onChange' }]">
            <v-select2 v-model="form.regionId" v-bind="tenantParams" @onChange="tenantChange" />
          </el-form-item>
          <el-form-item label="项目" :rules="[{ required: true, message: '请选择项目', trigger: 'change' }]">
            <chosen-list-panel :list.sync="form.communityIds" textAs="communityName" @select="communitySelect" @change="chosenChange"/>
          </el-form-item>
          <el-form-item label="授权应用">
            <chosen-list-panel :list.sync="form.subIds" textAs="subName" @select="select" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="showCommunityMultiSelect"
      :searchUrl="queryCommunityListURL"
      :searchParams="form"
      :headers="communityHeaders"
      :responseParams="communityResponseParams"
      :responseKey="communityResponseKey"
      :backFill.sync="form.communityIds"
      :extraParams="communityExtraParams"
       >
      <template #searchSlot>
        <v-input label="项目名称" v-model="form.communityName" />
      </template>
    </multi-select>
    <multi-select
      title="应用列表"
      :isShow.sync="showMultiSelect"
      :searchUrl="getApplicationListURL"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.subIds"
      :extraParams="communityExtraParams">
        <template #searchSlot>
          <v-input label="应用名称" v-model="searchParams.subName" />
          <v-select label="应用类别" v-model="searchParams.subHandle" :options="subHandleOps" />
          <v-select label="应用类型" v-model="searchParams.subType" :options="subTypeOps" />
          <v-input label="应用描述" v-model="searchParams.subNote" />
        </template>
    </multi-select>
  </div>
</template>

<script>
import { getGrantApplicationListURL, getApplicationListURL, queryCommunityListURL, bacthGrantApplicationURL } from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap, communityStatusMap } from '../map'
import { Col2Detail, Col2Block, ChosenListPanel, MultiSelect } from 'components/bussiness'
import { normalCommunityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'BatchProjectApplicationLibranyTenantForm',
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect
  },
  data () {
    return {
      submitUrl: bacthGrantApplicationURL,
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      communityParams: normalCommunityParams,
      showMultiSelect: false,
      showCommunityMultiSelect: false,
      form: {
        communityIds: [],
        subIds: [],
        communityName: '',
        regionId: undefined
      },
      getApplicationListURL,
      headers: [
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'subHandleText',
          label: '应用类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'subNote',
          label: '应用描述'
        }
      ],
      tenantParams,
      searchParams: {
        communityId: undefined,
        subName: '',
        subHandle: undefined,
        subType: undefined,
        subNote: ''
      },
      responseParams: {
        id: 'id',
        name: 'subName'
      },
      responseKey: {
        id: 'id',
        name: 'subName'
      },
      queryCommunityListURL,
      communityHeaders: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter: row => this.communityStatusMap[row.communityStatus]
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'province',
          label: '所在省'
        },
        {
          prop: 'city',
          label: '所在市'
        },
        {
          prop: 'area',
          label: '所在区'
        },

        {
          prop: 'regionName',
          label: '分子公司'
        }
      ],
      communityResponseParams: {
        id: 'communityId',
        name: 'communityName'
      },
      communityResponseKey: {
        id: 'id',
        name: 'communityName'
      }
    }
  },
  computed: {
    communityExtraParams () {
      return {
        regionId: this.form.regionId
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置项目授权应用')
    this.communityStatusMap = {...communityStatusMap, undefined: '全部'}
  },
  methods: {
    communitySelect () {
      if (this.form.regionId) {
        this.showCommunityMultiSelect = true
      } else {
        this.$message.info('请先选择公司')
      }
    },
    select () {
      if (this.form.regionId) {
        if (this.form.communityIds.length > 0) {
          this.showMultiSelect = true;
        }else{
          this.$message.info('请先选择项目')
        }
      } else {
        this.$message.info('请先选择公司')
      }
    },
    chosenChange (newValue) {
      if(!newValue || newValue.length === 0){
        this.form.subIds = []
        return
      }
    },
    submitBefore (data) {
      let subIds = []
      let communityIds = []
      data.subIds.forEach(item => {
        subIds.push(item.id)
      })
      data.communityIds.forEach(item => {
        communityIds.push(item.id)
      })
      data.communityIds = communityIds
      data.subIds = subIds
      return true
      
    },
    tenantChange (option, echo) {
      if (!echo) {
        if (this.form.communityIds && this.form.communityIds.length) {
          this.form.communityIds = []
        }
        if (this.form.subIds && this.form.subIds.length) {
          this.form.subIds = []
        }
      }
    }
  }
}
</script>
